import React from "react";
import { Transition } from "@headlessui/react";
import { useState } from "react";
import { Link } from "react-router-dom/dist";
import ezonepartners from "../Main/images/Ezone Partners.png";
import LoadingBar from "react-top-loading-bar";
function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  return (
    <>
      <LoadingBar
        color="#06B6D4"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="navbar  py-4	 pl-2 pr-2 relative font-Syne mt-1 font-bold font-Poppins text-xl whitespace-normal sm:whitespace-normal md:whitespace-nowrap lg:whitespace-nowrap">
        <nav className=" text-gray-400  bg-zinc-900 font-bold border hover:shadow hover:backdrop-blur-sm  border-zinc-800 rounded-xl p-3">
          <div className="w-full">
            <div className="flex items-center h-20 w-full">
              <div className="flex items-center  mx-20  justify-center sm:justify-center md:justify-between lg:justify-between w-full ">
                <div className="flex justify-center items-center flex-shrink-0 mr-12 -space-x-7">
                  <img
                    src={ezonepartners}
                    className="w-40 sm:w-32 mt-6 md:w-36 lg:w-32 object-contain max-h-full"
                    alt=""
                  />
                  <Link to="/" onClick={() => setProgress(100)}>
                    <h1 className=" font-bold text-md cursor-pointer">
                      Ezone<span className="text-cyan-500 "> Partners</span>
                    </h1>
                  </Link>
                </div>
                <div className="hidden md:block font-bold">
                  <div className="ml-10 flex text-3xl items-baseline space-x-4">
                    <Link
                      activeclassname="Home"
                      to="/"
                      offset={50}
                      duration={500}
                      onClick={() => setProgress(100)}
                      className="cursor-pointer hover:bg-cyan-600 text-white hover:text-white p-4 px-4 rounded-md text-sm font-medium"
                    >
                      Home
                    </Link>
                    <Link
                      activeclassname="about"
                      to="/about"
                      offset={50}
                      duration={500}
                      onClick={() => setProgress(100)}
                      className="cursor-pointer hover:bg-cyan-600 text-white hover:text-white p-4 px-4 rounded-md text-sm font-medium"
                    >
                      About
                    </Link>
                    <Link
                      activeclassname="services"
                      to="/services"
                      offset={50}
                      duration={500}
                      onClick={() => setProgress(100)}
                      className="cursor-pointer hover:bg-cyan-600 text-white hover:text-white p-4 px-4 rounded-md text-sm font-medium"
                    >
                      Services
                    </Link>
                    <Link
                      activeclassname="Contact"
                      to="/contact"
                      offset={50}
                      duration={500}
                      onClick={() => setProgress(100)}
                      className="cursor-pointer hover:bg-cyan-600 text-white hover:text-white block p-4 px-4 rounded-md text-base font-medium"
                    >
                      Contact
                    </Link>

                  </div>
                </div>
              </div>
              <div className="mr-12 flex md:hidden font-bold  ">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className="bg-cyan-600 inline-flex items-center justify-center p-2 mr-0 sm:mr-6 lg:mr-0 md:mr-0 rounded-md text-white  hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-cyan-800 focus:ring-white"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  {!isOpen ? (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>

          <Transition
            show={isOpen}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {(ref) => (
              <div className="md:hidden" id="mobile-menu">
                <div
                  ref={ref}
                  className="bg-zinc-900 px-4 pt-2 pb-3  space-y-1 sm:px-3 rounded-2xl"
                >
                  <Link
                    to="/"
                    activeclassname="home"
                    offset={50}
                    duration={500}
                    onClick={() => setProgress(100)}
                    className="cursor-pointer hover:bg-cyan-600 text-white hover:text-black block p-4 px-4 rounded-md text-base font-medium"
                  >
                    Home
                  </Link>
                  <Link
                    to="/about"
                    activeclassname="about"
                    offset={50}
                    duration={500}
                    onClick={() => setProgress(100)}
                    className="cursor-pointer hover:bg-cyan-600 text-white hover:text-black block p-4 px-4 rounded-md text-base font-medium"
                  >
                    About
                  </Link>

                  <Link
                    to="/services"
                    activeclassname="work"
                    offset={50}
                    duration={500}
                    onClick={() => setProgress(100)}
                    className="cursor-pointer hover:bg-cyan-600 text-white hover:text-black block p-4 px-4 rounded-md text-base font-medium"
                  >
                    Services
                  </Link>
                  <Link
                    to="/contact"
                    activeclassname="work"
                    offset={50}
                    duration={500}
                    onClick={() => setProgress(100)}
                    className="cursor-pointer hover:bg-cyan-600 text-white hover:text-black block p-4 px-4 rounded-md text-base font-medium"
                  >
                    Contact
                  </Link>
                </div>
              </div>
            )}
          </Transition>
        </nav>
      </div>
    </>
  );
}

export default Header;
