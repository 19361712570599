import React from "react";
import { RiNavigationFill } from "react-icons/ri";
import { IoCall } from "react-icons/io5";
function Whatsapp() {
  return (
    <>
      <div className="relative">
        <a
          href="https://wa.me/447842156532"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button
            className="z-50 p-4 lg:p-0 text-white flex flex-col shrink-0 grow-0 justify-around 
                  fixed bottom-0  right-5 rounded-lg
                  mr-1 mb-5 lg:mr-5 lg:mb-5 xl:mr-10 xl:mb-10 "
          >
            <div className="p-3 rounded-full border-4 border-white bg-green-600">
              <IoCall className="h-7  w-7 text-white" />
            </div>
          </button>
        </a>
      </div>
    </>
  );
}

export default Whatsapp;
