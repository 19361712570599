import React from "react";
function Contact() {
  return (
    <>
      <h1 className="text-5xl text-white mt-4 mb-14 font-black tracking-wider drop-shadow-lg text-center font-Poppins">
        Contact Us
      </h1>
      <section className="text-gray-600 body-font relative mb-14">
        <div className="absolute inset-0 bg-black m-4  lg:visible md:visible sm:visible ">
          <iframe
            width="100%"
            height="100%"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            className="rounded-xl hidden sm:flex lg:flex md:flex"
            title="map"
            scrolling="no"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21967104.25016378!2d-42.81130746817957!3d47.77114528869727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x25a3b1142c791a9%3A0xc4f8a0433288257a!2sUnited%20Kingdom!5e0!3m2!1sen!2sus!4v1713473222453!5m2!1sen!2sus"
          ></iframe>
        </div>
        <div className="container  px-5 py-12 mx-auto flex ">
          <div className="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8   flex flex-col md:ml-auto w-full justify-center md:mt-0 relative z-10 shadow-md">
            <h2 className="text-gray-900 text-lg mb-1  font-medium title-font">
              Contact Us
            </h2>
            <div className="flex items-center justify-center mt-6 mb-4 ">
              <a
                className="flex items-center justify-center px-6 py-3 bg-green-500 text-white text-sm font-medium rounded-lg focus:outline-none focus:shadow-outline hover:bg-green-600 transition duration-150 ease-in-out"
                href="https://wa.me/447842156532"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  className="w-6 h-6 mr-3"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <span className="tracking-wide">Chat with us on WhatsApp</span>
              </a>
            </div>
            <div className="mt-6 mb-4 m-11">
              <a
                className="flex items-center justify-center px-6 py-3 bg-indigo-600 text-white text-sm font-medium rounded-lg focus:outline-none focus:shadow-outline hover:bg-indigo-700 transition duration-150 ease-in-out"
                href="mailto:fawadkhan4715@gmail.com"
              >
                <svg
                  className="w-6 h-6 mr-3"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <span className="tracking-wide">Email Ezone Partners </span>
              </a>
            </div>

            <p className="leading-relaxed my-10 text-gray-600 ">     
            We believe in earning your trust and delivering results
                        before asking for payment. That's why we don't charge
                        anything upfront. Our success is tied to yours.       
            </p>
  
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
