import React from "react";
import { FaBoxes } from "react-icons/fa";
import { FaWarehouse } from "react-icons/fa";
import { IoShieldCheckmarkSharp } from "react-icons/io5";
import { FaHandsHelping } from "react-icons/fa";
import { BsGraphUpArrow } from "react-icons/bs";
import { FaTruckLoading } from "react-icons/fa";
function Servicesmain() {
  return (
    <>
      <>
        {/* component */}
        <div
          id="services"
          className="section relative pt-20 pb-8 md:pt-16 md:pb-0 bg-black font-Poppins"
        >
          <div className="container xl:max-w-6xl mx-auto px-4">
            {/* Heading start */}
            <header className="text-center mx-auto mb-12 lg:px-20">
              <h2 className="text-2xl leading-normal mb-2 font-bold text-white">
                What We Will Do For You
              </h2>
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 60"
                style={{ margin: "0 auto", height: 35 }}
                xmlSpace="preserve"
              >
                <circle
                  cx="50.1"
                  cy="30.4"
                  r={5}
                  className="stroke-primary"
                  style={{
                    fill: "transparent",
                    strokeWidth: 2,
                    strokeMiterlimit: 10,
                  }}
                />
                <line
                  x1="55.1"
                  y1="30.4"
                  x2={100}
                  y2="30.4"
                  className="stroke-primary"
                  style={{ strokeWidth: 2, strokeMiterlimit: 10 }}
                />
                <line
                  x1="45.1"
                  y1="30.4"
                  x2={0}
                  y2="30.4"
                  className="stroke-primary"
                  style={{ strokeWidth: 2, strokeMiterlimit: 10 }}
                />
              </svg>
              <p className="text-gray-500 leading-relaxed font-light text-xl mx-auto pb-2">
                These are the things that we would take care of.
              </p>
            </header>
            {/* End heading */}
            {/* row */}
            <div className="flex flex-wrap flex-row -mx-4 text-center">
              <div
                className="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
                data-wow-duration="1s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationName: "fadeInUp",
                }}
              >
                {/* service block */}
                <div className="py-8 px-12 mb-12 bg-zinc-800 rounded-xl border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                  <div className="inline-block text-white mb-4">
                    {/* icon */}
                    <FaBoxes className="w-10 h-10" />
                  </div>
                  <h3 className="text-lg leading-normal mb-2 font-semibold text-white">
                    Product Sourcing
                  </h3>
                  <p className="text-gray-500">
                    Unlock unparalleled product sourcing potential through 1688,
                    tapping into a vast marketplace of high-quality goods at
                    competitive prices, ensuring your business stays ahead of
                    the curve.
                  </p>
                </div>
                {/* end service block */}
              </div>
              <div
                className="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".1s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: "0.1s",
                  animationName: "fadeInUp",
                }}
              >
                {/* service block */}
                <div className="py-8 px-12 mb-12 bg-zinc-800 rounded-xl border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                  <div className="inline-block text-white mb-4">
                    {/* icon */}
                    <FaWarehouse className="w-10 h-10" />
                  </div>
                  <h3 className="text-lg leading-normal mb-2 font-semibold text-white text-nowrap">
                    1688 supplier payments
                  </h3>
                  <p className="text-gray-500">
                    With your trusted 1688 supplier in place, leave the payment
                    worries to us. Our seamless service ensures hassle-free
                    payments, allowing you to focus on growing your business.
                  </p>
                </div>
                {/* end service block */}
              </div>
              <div
                className="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".3s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: "0.3s",
                  animationName: "fadeInUp",
                }}
              >
                {/* service block */}
                <div className="py-11 px-12 mb-10 bg-zinc-800 rounded-xl border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2 ">
                  <div className="inline-block text-white mb-2">
                    {/* icon */}
                    <BsGraphUpArrow className="w-10 h-10" />
                  </div>
                  <h3 className="text-lg leading-normal mb-2 font-semibold text-white">
                    Quality Assurance
                  </h3>
                  <p className="text-gray-500 -mb-4">
                    Our meticulous processes ensure the highest standards of
                    product quality, safeguarding your reputation and ensuring
                    customer satisfaction with every purchase.
                  </p>
                </div>
                {/* end service block */}
              </div>
              <div
                className="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
                data-wow-duration="1s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationName: "fadeInUp",
                }}
              >
                {/* service block */}
                <div className="py-7 px-12 mb-12 bg-zinc-800 rounded-xl border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                  <div className="inline-block text-white mb-4">
                    {/* icon */}
                    <FaHandsHelping className="w-10 h-10" />
                  </div>
                  <h3 className="text-lg leading-normal mb-2 font-semibold text-white text-nowrap">
                    Efficient Freight Forwarding
                  </h3>
                  <p className="text-gray-500">
                    Seamlessly navigate the complexities of freight forwarding
                    with our expert solutions, ensuring timely and
                    cost-effective transportation of your goods to their
                    destination.
                  </p>
                </div>
                {/* end service block */}
              </div>
              <div
                className="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".1s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: "0.1s",
                  animationName: "fadeInUp",
                }}
              >
                {/* service block */}
                <div className="py-10 px-12 mb-12 bg-zinc-800 rounded-xl border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                  <div className="inline-block text-white mb-4">
                    {/* icon */}

                    <IoShieldCheckmarkSharp className="w-10 h-10" />
                  </div>
                  <h3 className="text-lg leading-normal mb-2 font-semibold text-white ">
                    Risk Management
                  </h3>
                  <p className="text-gray-500 -mb-2 -my-4 py-6">
                    Effortlessly receive payments on the trusted Alibaba
                    platform, ensuring security and convenience for your
                    transactions.
                  </p>
                </div>
                {/* end service block */}
              </div>
              <div
                className="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".3s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: "0.3s",
                  animationName: "fadeInUp",
                }}
              >
                {/* service block */}
                <div className="py-8 px-12 mb-12 bg-zinc-800 rounded-xl border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                  <div className="inline-block text-white mb-4">
                    {/* icon */}
                    <FaTruckLoading className="w-9 h-10" />
                  </div>
                  <h3 className="text-lg leading-normal mb-2 font-semibold text-white ">
                    Scalability
                  </h3>
                  <p className="text-gray-500 text-center -my-4 py-9">
                    Our platform seamlessly scales with your business, ensuring
                    you can expand confidently without limitations.
                  </p>
                </div>
                {/* end service block */}
              </div>
            </div>
            {/* end row */}
          </div>
          <div className="mx-auto max-w-md py-8 w-1/5 my-10 rounded-2xl border-8">
            <p className="text-white font-bold font-inter text-center">
              Need more info? Click&nbsp;{" "}
              <a
                href="https://wa.me/447842156532"
                target="_blank"
                rel="noopener noreferrer"
                className="text-cyan-500"
              >
                Here
              </a>
            </p>
          </div>
        </div>
      </>
    </>
  );
}

export default Servicesmain;
