import React from "react";

function Compare() {
  return (
    <>
          <div className="bg-opacity-10 bg-black  text-white py-14 font-Poppins">
        <h1 className="text-5xl font-black tracking-wider drop-shadow-lg text-center m-1">
          1688 Comparison
        </h1>
      </div>
      <section className="flex flex-wrap mx-3 justify-center space-y-4 lg:space-y-0 md:space-y-0 space-x-0 md:space-x-4 lg:space-x-4">

      <div className="relative flex bg-clip-border rounded-xl bg-white text-gray-700 shadow-md w-full sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/4 flex-row">
        <div className="relative w-2/5 m-0 overflow-hidden text-gray-700 bg-white rounded-r-none bg-clip-border rounded-xl shrink-0">
          <img
            src="https://images.unsplash.com/photo-1621607511583-8bd7dc889b5f?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="hair straightener"
            className="object-cover w-full h-full"
          />
        </div>
        <div className="p-6">
          <h6 className="block mb-4 font-Poppins text-base antialiased font-semibold leading-relaxed tracking-normal text-gray-700 uppercase">
            hair straightener
          </h6>
          <br />
          <h4 className="block mb-2 font-Poppins text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
            From Alibaba
          </h4>
          <p className="block mb-8 font-Poppins text-base antialiased font-semibold leading-relaxed text-gray-700">
            Price : $2.47 for 50 peices
            <br />
            MOQ : 50 peices 
            <br />
            Products send from agents
            <br />
            Reviews : No Reviews
            <br />
          </p>
          <h4 className="block mb-2 font-Poppins text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
           No ensurance the product quality from the agent and other high risks are also involved
          </h4>
          
        </div>
      </div>
      <div className="relative flex bg-clip-border rounded-xl bg-white text-gray-700 shadow-md w-full sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/4 flex-row">
        <div className="relative w-2/5 m-0 overflow-hidden text-gray-700 bg-white rounded-r-none bg-clip-border rounded-xl shrink-0">
          <img
            src="https://images.unsplash.com/photo-1621607511583-8bd7dc889b5f?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="hair straightener"
            className="object-cover w-full h-full"
          />
        </div>
        <div className="p-6">
          <h6 className="block mb-4 font-Poppins text-base antialiased font-semibold leading-relaxed tracking-normal text-gray-700 uppercase">
            hair straightener
          </h6>
          <br />
          <h4 className="block mb-2 font-Poppins text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
            From 1688 Suppliers
          </h4>
          <p className="block mb-8 font-Poppins text-base antialiased font-semibold leading-relaxed text-gray-700">
            Price : $1.76 for 50 peices
            <br />
            MOQ : As low as 2 peices
            <br />
            Product send directly from manufacturers.
            <br />
            Reviews : 80+ Five Star Reviews
            <br />
          </p>
          <h4 className="block mb-2 font-Poppins text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
            We will ensure the product quality for you from the supplier and ship the product to your selected address.
          </h4>
          
        </div>
      </div>
      </section>
    </>
  );
}

export default Compare;
