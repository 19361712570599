import React from "react";
import warehouse from "../Main/images/warehouse.jpg";
import { FaHeadset } from "react-icons/fa6";
import { IoShieldCheckmark } from "react-icons/io5";
import { FaCircleCheck } from "react-icons/fa6";
import { MdOutlineAttachMoney } from "react-icons/md";
import { GrOptimize } from "react-icons/gr";
import { FaBoxesPacking } from "react-icons/fa6";
function About() {
  return (
    <>
      <h1 className="text-5xl font-black tracking-wider drop-shadow-lg text-center text-white mt-4 font-Poppins">
        Our Mission
      </h1>
      <style
        dangerouslySetInnerHTML={{
          __html:
            '\n\t.component {\n\t\tfont-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;\n\t}\n\n\tblockquote {\n\t\tbackground:\n\t\t\tlinear-gradient(to right, #039be5 6px, transparent 4px) 0 100%,\n\t\t\tlinear-gradient(to left, #039be5 6px, transparent 4px) 100% 0,\n\t\t\tlinear-gradient(to bottom, #039be5 6px, transparent 4px) 100% 0,\n\t\t\tlinear-gradient(to top, #039be5 6px, transparent 4px) 0 100%;\n\t\tbackground-repeat: no-repeat;\n\t\tbackground-size: 20px 20px;\n\t}\n\n\t/* -- create the quotation marks -- */\n\tblockquote:before,\n\tblockquote:after {\n\t\tfont-family: FontAwesome;\n\t\tposition: absolute;\n\t\tcolor: #039be5;\n\t\tfont-size: 34px;\n\t}\n\n\tblockquote:before {\n\t\tcontent: "\\f10d";\n\t\ttop: -12px;\n\t\tmargin-right: -20px;\n\t\tright: 100%;\n\t}\n\n\tblockquote:after {\n\t\tcontent: "\\f10e";\n\t\tmargin-left: -20px;\n\t\tleft: 100%;\n\t\ttop: auto;\n\t\tbottom: -20px;\n\t}\n',
        }}
      />
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"
      />
      <section className="component bg-black p-10 mx-1 md:mx-10  ">
        <blockquote className="relative text-white text-center p-10 text-md lg:text-2xl md:text-2xl  w-full m-1 font-Poppins">
          If you're tasked with managing supplier payments on the renowned 1688
          platform, you've come to the right place
        </blockquote>
      </section>
      <section className="text-cyan-500 body-font font-Poppins">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <img
              className="object-cover object-center rounded-3xl"
              alt="hero"
              src={warehouse}
            />
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-cyan-500">
              About Us
              <br className="hidden lg:inline-block" />
            </h1>
            <p className="mb-8 leading-relaxed text-white">
              We're dedicated to revolutionizing the
              way businesses source products. With a focus on efficiency,
              transparency, and cost-effectiveness, we specialize in connecting
              businesses directly with manufacturers on the 1688 platform. Our
              team of experts is committed to providing unparalleled support,
              ensuring a seamless experience from product discovery to delivery.
              Trust us to elevate your sourcing experience and help your
              business thrive.
            </p>
          </div>
        </div>
      </section>

      <div className="max-w-screen-xl mx-auto px-5 text-white bg-black min-h-sceen font-Poppins">
        <div className="flex flex-col items-center">
          <h2 className="font-bold text-5xl mt-5 tracking-tight">FAQ</h2>
          <p className="text-white text-xl mt-3">
            Some Q/A regarding our service
          </p>
        </div>
        <div className="grid divide-y divide-neutral-200 max-w-xl mx-auto mt-8">
          <div className="py-5">
            <details className="group">
              <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                <span> What is 1688?</span>
                <span className="transition group-open:rotate-180">
                  <svg
                    fill="none"
                    height={24}
                    shapeRendering="geometricPrecision"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    width={24}
                  >
                    <path d="M6 9l6 6 6-6" />
                  </svg>
                </span>
              </summary>
              <p className="text-white mt-3 group-open:animate-fadeIn">
                
1688.com is a popular Chinese wholesale marketplace owned by Alibaba Group. It serves as a platform for businesses to buy products in bulk directly from manufacturers and wholesalers in China.
              </p>
            </details>
          </div>
          <div className="py-5">
            <details className="group">
              <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                <span> What is the main benefit of your service?</span>
                <span className="transition group-open:rotate-180">
                  <svg
                    fill="none"
                    height={24}
                    shapeRendering="geometricPrecision"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    width={24}
                  >
                    <path d="M6 9l6 6 6-6" />
                  </svg>
                </span>
              </summary>
              <p className="text-white mt-3 group-open:animate-fadeIn">
                Streamline your sourcing from 1688 with our tailored payment
                solutions, ensuring you secure the lowest prices with seamless
                transaction management. mutual.
              </p>
            </details>
          </div>
          <div className="py-5">
            <details className="group">
              <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                <span> Shipping to my selected address.</span>
                <span className="transition group-open:rotate-180">
                  <svg
                    fill="none"
                    height={24}
                    shapeRendering="geometricPrecision"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    width={24}
                  >
                    <path d="M6 9l6 6 6-6" />
                  </svg>
                </span>
              </summary>
              <p className="text-white mt-3 group-open:animate-fadeIn">
                Effortlessly ship your 1688 purchases to your chosen address
                with our integrated shipping solutions, ensuring convenience and
                peace of mind every step of the way.
              </p>
            </details>
          </div>
          <div className="py-5">
            <details className="group">
              <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                <span> How do I pay my 1688 suppliers ?</span>
                <span className="transition group-open:rotate-180">
                  <svg
                    fill="none"
                    height={24}
                    shapeRendering="geometricPrecision"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    width={24}
                  >
                    <path d="M6 9l6 6 6-6" />
                  </svg>
                </span>
              </summary>
              <p className="text-white mt-3 group-open:animate-fadeIn">
                We will send you the bills on your Alibaba Account. Ensuring the saftey of your funds.
              </p>
            </details>
          </div>
        </div>
      </div>

      <section className=" bg-blue-500ray-50 font-Poppins">
        <div className="py-10  sm:py-10 block lg:py-10 relative bg-opacity-50  z-40  ">
          <div className="relative mx-auto h-full px-4 pb-20   md:pb-10 sm:max-w-xl md:max-w-full md:px-24 lg:max-w-screen-xl lg:px-8">
            <div className=" absolute  top-0 -right-1/4 z-0  opacity-10 ">
              <svg
                width="800px"
                height="800px"
                viewBox="0 0 24 24"
                className="w-96 z-0  h-full    object-fill fill-g text-g"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 6C12 5.44772 11.5523 5 11 5C10.4477 5 10 5.44772 10 6V16C10 16.5523 10.4477 17 11 17C11.5523 17 12 16.5523 12 16V6ZM9 9C9 8.44772 8.55228 8 8 8C7.44772 8 7 8.44772 7 9V16C7 16.5523 7.44772 17 8 17C8.55228 17 9 16.5523 9 16V9ZM15 9C15 8.44772 14.5523 8 14 8C13.4477 8 13 8.44772 13 9V16C13 16.5523 13.4477 17 14 17C14.5523 17 15 16.5523 15 16V9ZM18 13C18 12.4477 17.5523 12 17 12C16.4477 12 16 12.4477 16 13V16C16 16.5523 16.4477 17 17 17C17.5523 17 18 16.5523 18 16V13ZM6 15C6 14.4477 5.55228 14 5 14C4.44772 14 4 14.4477 4 15V16C4 16.5523 4.44772 17 5 17C5.55228 17 6 16.5523 6 16V15ZM21 15C21 14.4477 20.5523 14 20 14C19.4477 14 19 14.4477 19 15V16C19 16.5523 19.4477 17 20 17C20.5523 17 21 16.5523 21 16V15ZM4 18C3.44772 18 3 18.4477 3 19C3 19.5523 3.44772 20 4 20H21C21.5523 20 22 19.5523 22 19C22 18.4477 21.5523 18 21 18H4Z"
                />
              </svg>
            </div>
            <div className=" absolute -bottom-0 -left-1/4 z-0  opacity-10 ">
              <svg
                width="800px"
                height="800px"
                viewBox="0 0 256 256"
                xmlns="http://www.w3.org/2000/svg"
                className="w-48 z-0  h-full -rotate-90   object-fill fill-r text-r"
              >
                <path
                  d="M32 225h12.993A4.004 4.004 0 0 0 49 220.997V138.01c0-4.976.724-5.04 1.614-.16l12.167 66.708c.397 2.177 2.516 3.942 4.713 3.942h8.512a3.937 3.937 0 0 0 3.947-4S79 127.5 80 129s14.488 52.67 14.488 52.67c.559 2.115 2.8 3.83 5.008 3.83h8.008a3.993 3.993 0 0 0 3.996-3.995v-43.506c0-4.97 1.82-5.412 4.079-.965l10.608 20.895c1.001 1.972 3.604 3.571 5.806 3.571h9.514a3.999 3.999 0 0 0 3.993-4.001v-19.49c0-4.975 2.751-6.074 6.155-2.443l6.111 6.518c1.51 1.61 4.528 2.916 6.734 2.916h7c2.21 0 5.567-.855 7.52-1.92l9.46-5.16c1.944-1.06 5.309-1.92 7.524-1.92h23.992a4.002 4.002 0 0 0 4.004-3.992v-7.516a3.996 3.996 0 0 0-4.004-3.992h-23.992c-2.211 0-5.601.823-7.564 1.834l-4.932 2.54c-4.423 2.279-12.028 3.858-16.993 3.527l2.97.198c-4.962-.33-10.942-4.12-13.356-8.467l-11.19-20.14c-1.07-1.929-3.733-3.492-5.939-3.492h-7c-2.21 0-4 1.794-4 4.001v19.49c0 4.975-1.14 5.138-2.542.382l-12.827-43.535c-.625-2.12-2.92-3.838-5.127-3.838h-8.008c-2.207 0-3.916 1.784-3.817 4.005l1.92 42.998c.221 4.969-.489 5.068-1.585.224l-15.13-66.825c-.488-2.155-2.681-3.902-4.878-3.902h-8.512a3.937 3.937 0 0 0-3.947 4s.953 77-.047 75.5-13.937-92.072-13.937-92.072C49.252 34.758 47.21 33 45 33H31.999"
                  fillRule="evenodd"
                />
              </svg>
            </div>
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 relative">
              <div className="max-w-xl mx-auto text-center">
                <div className="inline-flex px-4 py-1.5 mx-auto rounded-full  ">
                  <p className="text-4xl font-semibold tracking-widest text-g uppercase text-white">
                    Why choose us:
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-2 mt-12 sm:grid-cols-3 lg:mt-20   ">
                <a
                  href="#!"
                  className="transition-all  duration-1000 bg-blue-600 hover:bg-blue-500  hover:shadow-xl m-2 p-4 relative z-40 group  "
                >
                  <div className=" absolute  bg-cyan-500/50 top-0 left-0 w-24 h-1 z-30  transition-all duration-200   group-hover:bg-white group-hover:w-1/2  "></div>
                  <div className="py-2 px-9 relative">
                    <MdOutlineAttachMoney className="w-16 h-16 fill-white group-hover:fill-white" />
                    <h3 className="mt-8 text-lg font-semibold text-white group-hover:text-white ">
                      Reliability
                    </h3>
                    <p className="mt-4 text-md text-base text-white group-hover:text-white  ">
                      Rely on a platform built for stability and reliability.
                      With our proven track record and industry-leading security
                      measures, you can trust that your supplier payments are in
                      good hands.
                    </p>
                  </div>
                </a>
                <a
                  href="#!"
                  className="transition-all  duration-1000 bg-blue-600 hover:bg-blue-500  hover:shadow-xl m-2 p-4 relative z-40 group  "
                >
                  <div className=" absolute  bg-cyan-500/50 top-0 left-0 w-24 h-1 z-30  transition-all duration-200   group-hover:bg-white group-hover:w-1/2  "></div>
                  <div className="py-2 px-9 relative">
                    <GrOptimize className="w-16 h-16 text-white group-hover:fill-white" />
                    <h3 className="mt-8 text-lg font-semibold text-white group-hover:text-white ">
                      Efficiency
                    </h3>
                    <p className="mt-4 text-md text-base text-white group-hover:text-white  ">
                      Streamline your workflow and increase productivity with
                      our efficient management. Spend less time on manual tasks
                      and more time on strategic initiatives.
                    </p>
                  </div>
                </a>
                <a
                  href="#!"
                  className="transition-all  duration-1000 bg-blue-600 hover:bg-blue-500  hover:shadow-xl m-2 p-4 relative z-40 group  "
                >
                  <div className=" absolute  bg-cyan-500/50 top-0 left-0 w-24 h-1 z-30  transition-all duration-200   group-hover:bg-white group-hover:w-1/2  "></div>
                  <div className="py-2 px-9 relative">
                    <FaBoxesPacking className="w-16 h-16 fill-white group-hover:fill-white" />
                    <h3 className="mt-8 text-lg font-semibold text-white group-hover:text-white ">
                      Transparency
                    </h3>
                    <p className="mt-4 text-md text-base text-white group-hover:text-white  ">
                      Gain full visibility into your payment processes and
                      supplier relationships. Our transparent reporting and
                      analytics empower you to make informed decisions and
                      optimize your payment strategy.
                    </p>
                  </div>
                </a>
                <a
                  href="#!"
                  className="transition-all  duration-1000 bg-blue-600 hover:bg-blue-500  hover:shadow-xl m-2 p-4 relative z-40 group  "
                >
                  <div className=" absolute  bg-cyan-500/50 top-0 left-0 w-24 h-1 z-30  transition-all duration-200   group-hover:bg-white group-hover:w-1/2  "></div>
                  <div className="py-2 px-9 relative">
                    <FaHeadset className="w-16 h-16 fill-white group-hover:fill-white" />
                    <h3 className="mt-8 text-lg font-semibold text-white group-hover:text-white ">
                      24/7 Support
                    </h3>
                    <p className="mt-4 text-md text-base text-white group-hover:text-white  ">
                      We offer round-the-clock support to ensure our customers
                      receive assistance whenever they encounter any issues with
                      our services.
                    </p>
                  </div>
                </a>

                <a
                  href="#!"
                  className="transition-all  duration-1000 bg-blue-600 hover:bg-blue-500  hover:shadow-xl m-2 p-4 relative z-40 group  "
                >
                  <div className=" absolute  bg-cyan-500/50 top-0 left-0 w-24 h-1 z-30  transition-all duration-200   group-hover:bg-white group-hover:w-1/2  "></div>
                  <div className="py-2 px-9 relative  ">
                    <IoShieldCheckmark className="w-16 h-16 fill-white group-hover:fill-white" />
                    <h3 className="mt-8 text-lg font-semibold text-white group-hover:text-white ">
                      Integrity
                    </h3>
                    <p className="mt-4 text-md text-base text-white group-hover:text-white">
                      We ensure the safety and integrity of our customers
                      products, data, and operations through our extensive
                      security measures to get the highest level of performance.
                    </p>
                  </div>
                </a>

                <a
                  href="#!"
                  className="transition-all  duration-1000 bg-blue-600 hover:bg-blue-500  hover:shadow-xl m-2 p-4 relative z-40 group  "
                >
                  <div className=" absolute  bg-cyan-500/50 top-0 left-0 w-24 h-1 z-30  transition-all duration-200   group-hover:bg-white group-hover:w-1/2  "></div>
                  <div className="py-2 px-9 relative  ">
                    <FaCircleCheck className="w-16 h-16 fill-white group-hover:fill-white" />
                    <h3 className="mt-8 text-lg font-semibold text-white group-hover:text-white ">
                      Perfomance and Execution
                    </h3>
                    <p className="mt-4 text-md text-base text-white group-hover:text-white">
                      We have an impeccable track record of timely delivery,
                      staying within budget, and consistently achieving
                      excellence. Our team boast extensive experience in
                      flawlessly executing work.
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
