import React from "react";
import { VscServerProcess } from "react-icons/vsc";
import { PiMagnifyingGlassDuotone } from "react-icons/pi";
import { FaGlobeAmericas } from "react-icons/fa";

function Services() {
  return (
    <>
      <div className="bg-opacity-10 bg-black  text-white py-14 font-Poppins">
        <h1 className="text-5xl font-black tracking-wider drop-shadow-lg text-center m-1">
          Our Services
        </h1>
      </div>

      <div className="px-3 md:lg:xl:px-40  py-20 bg-opacity-10 font-Poppins">
        <div className="grid grid-cols-1 md:lg:xl:grid-cols-3 group bg-zinc-900 shadow-xl shadow-cyan-500 border border-black">
          <div className="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b border-black  hover:bg-blue-800 cursor-pointer">
            <span className="p-5 rounded-full bg-blue-500 text-white shadow-lg shadow-blue-200">
              <PiMagnifyingGlassDuotone className="h-10 w-10" />
            </span>
            <p className="text-xl font-medium text-white mt-3 text-nowrap">
              Product Sourcing
            </p>
            <p className="mt-2 text-sm text-white ">
            Send us the product details, and we'll hunt down the best products at the most competitive prices for you. We will also ensure the quality.
            </p>
          </div>
          <div className="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b border-black  hover:bg-red-800 cursor-pointer">
            <span className="p-5 rounded-full bg-red-500 text-white shadow-lg shadow-red-200">
              <VscServerProcess className="h-10 w-10" />
            </span>
            <p className="text-xl font-medium text-white mt-3 text-nowrap">
              Streamlined Payment Processing
            </p>
            <p className="mt-2 text-sm text-white ">
              We will manage the supplier payments, we will send you the payment on your Alibaba account  for the saftey of your funds.
            </p>
          </div>

          <div className="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b border-black  hover:bg-orange-800 cursor-pointer">
            <span className="p-5 rounded-full bg-orange-500 text-white shadow-lg shadow-orange-200">
              <FaGlobeAmericas className="h-10 w-10" />
            </span>
            <p className="text-xl font-medium text-white mt-3">
            Freight forwarding 
            </p>
            <p className="mt-2 text-sm text-white ">
             We will send your Products to your selected address at the most reasonable price and with the best quality.
            </p>
          </div>


         
        </div>
      </div>

     
    </>
  );
}

export default Services;
