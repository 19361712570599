import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Main/Home";
import Header from "./components/Headers/Header";
import Footer from "./components/Footer/Footer";
import Services from "./components/Main/Services";
import Contact from "./components/Main/Contactus";
import About from "./components/Main/About";
import Pricing from "./components/Main/Pricing";
import Servicesmain from "./components/Main/Servicesmain";
import Scrolltotop from "./components/Main/scrolltotop";
import Whatsapp from "./components/Main/Whatsapp";
import Compare from "./components/Main/Compare";
import Feedback from "./components/Main/Feedback";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Whatsapp  />
      <Scrolltotop>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Home />
                <Services />
                <Compare/>
                <Feedback/>
              </>
            }
          />
          <Route path="/services" element={<Servicesmain />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </Scrolltotop>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
